import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const ConsumablesPartsPage = ({data}) => (

    <Layout pageClass='consumables-page'>
    <SEO title="Consumables and Parts" />
        <main className='main'>
            <div className='typography inner'>
                <h1>Consumables and Parts</h1>
                <p>With well over 35 references of different products we are one of the largest <span class="red bold">GLOBAL</span> suppliers on the market.
                        We are able to develop and produce standard & special thermal ribbons, labels and <span class="red bold">INK JET</span> cartridges on demand according
                        to your specific requests and needs, regardless of the make and model of the label printer, applicators or <span class="red bold">INK JET</span> coder…. 
                        Ensuring the highest quality standard at the most cost effective pricing. </p>

                <div className="consumables-holder">
                    <div className="consumables">
                        <div className="img">
                            <img src={data.allImageSharp.nodes[1].fixed.src} alt="Labels" />
                        </div>
                        <h4>Labels</h4>
                        <ul>
                            <li>Permanent</li>
                            <li>Removable</li>
                            <li>Matt</li>
                            <li>Gloss</li>
                            <li>All shapes and sizes to suit any make & model of printer</li>
                        </ul>
                    </div>
                    <div className="consumables">
                        <div className="img">
                            <img src={data.allImageSharp.nodes[2].fixed.src} alt="Thermal Ribbons" />
                        </div>
                        <h4>Thermal Ribbons</h4>
                        <ul>
                            <li>Easyprint, Dataflex, Smartdate, Intellidate, Imaje, SATO,
                            SAITO, ZEBRA, UBI, ELTRON, DATAMAX</li>
 
                        </ul>
                    </div>
                    <div className="consumables">
                        <div className="img">
                            <img src={data.allImageSharp.nodes[0].fixed.src} alt="Cartridge Printer Inks" />
                        </div>
                        <h4>Cartridge Printer Inks</h4>
                        <ul>
                            <li>Thermal Ink Jet Compatible Cartridges</li>
                        </ul>
                    </div>
                    <div className="consumables">
                        <div className="img">
                            <img src={data.allImageSharp.nodes[3].fixed.src} alt="Thermal Heads" />
                        </div>
                        <h4>Thermal Heads</h4>
                        <ul>
                            <li>Avery, Bell-Mark, Bizerba, Cab, Citizen, Datamax, Dibal,
                            Domino, Easyprint, Ice, Italora, Leptons, Linx, 
                            Markem-Imaje, mectec, Mettler Toledo, Opendate
                            Toshiba Tec, Valentins, Videojet</li>
                        </ul>
                    </div>
                    <div className="consumables">
                        <div className="img">
                            <img src={data.allImageSharp.nodes[4].fixed.src} alt="Printing Software" />
                        </div>
                        <h4>Printing Software</h4>
                        
                    </div>

                </div>
            </div>
        </main> 
    </Layout>

)

export const query = graphql`
    query {
        allImageSharp(filter: {fluid: {originalName: {in: ["Labels.jpg", "Ribbons-2.jpg", "HP45-Cartridges.jpg", "Thermal-Heads.jpg", "XB-4-JET-mit-Monitor.jpg"]}}}, sort: {order: ASC, fields: fluid___originalName}) {
        nodes {
            fixed(width: 200, height: 120) {
            src
            originalName
            }
        }
        }
    }
  
`

export default ConsumablesPartsPage